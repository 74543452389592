html,
body,
#root {
    background-color: #000000  !important;
}

#header {
    font-family: 'Oswald' !important;
}

div,
p,
h1,
h2,
h4,
span,
button,
h3,
a {
    font-family: 'Roboto' !important;
}