.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
    background-color: #5ebfdc !important;
}

.app-header {
    font-family: "Oswald", sans-serif !important;
}

.ui.dropdown.notifications .menu > * {
    white-space: normal !important;
    line-height: 1.2 !important;
    padding: 0 !important;
}

.ui.list .list > .formal-wrapper-item.item, .ui.list > .formal-wrapper-item.item, ol.ui.list li.formal-wrapper-item, ul.ui.list li.formal-wrapper-item {
    padding: 0 !important;
}
